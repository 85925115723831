import { Box, Button, TextField, Typography } from "@mui/material"
import { useState } from "react"

import alykkyys1 from '../Imgs/alykkyys1.png'

const Aloitus = () => {
    const [step, setStep] = useState(0)
    const [IQ, setIQ] = useState(null)

    const next = () => setStep(step + 1)
    const final = (iq) => {
        setIQ(iq)
        setStep(4)
    }


    if (step === 1) {
        return <Vaihe1 next={next} />
    } else if (step === 2) {
        return <Vaihe2 next={next} />
    } else if (step === 3) {
        return <Vaihe3 final={final} />
    } else if (IQ) {
        return <Final iq={IQ} />
    }


    return (
        <Box sx={{ mt: 2 }}>
            <Typography variant='h3' component='h1'>Älykkyystesti</Typography>
            <Typography paragraph>
                Essee.tk:n avulla voit nyt laskea luotettavasti älykkyysosamääräsi muutaman yksinkertaisen kysymyksen perusteella. Testi kestää muutaman minuutin, ja saat 
                testin tuloksena älykkyysosamääräsi tietoosi. Paina "Aloita testi", kun olet valmis aloittamaan testin.
            </Typography>

            <Button
                variant='contained'
                onClick={() => setStep(1)}
            >
                Aloita testi
            </Button>
        </Box>
    )
    

}

const Vaihe1 = ({ next }) => {
    return (
        <>
            <Typography sx={{ mt: 2 }} variant='h3' component='h1'>Valintatehtävä</Typography>

            <Typography paragraph>Valitse mielestäsi oikea vaihtoehto</Typography>

            <img src={alykkyys1} alt='Älykkyystestin kysymys' style={{ maxWidth: '90%' }} />

            <br />

            <Button onClick={next} variant='outlined' sx={{ mr: 1 }}>Neliö</Button>
            <Button onClick={next} variant='outlined' sx={{ mr: 1 }}>Ympyrä</Button>
            <Button onClick={next} variant='outlined' sx={{ mr: 1 }}>Kolmio</Button>
            <Button onClick={next} variant='outlined' sx={{ mr: 1 }}>Pertti</Button>


        </>
    )
}

const Vaihe2 = ({ next }) => {
    return (
        <>
            <Typography sx={{ mt: 2 }} variant='h3' component='h1'>Kuuntelutehtävä</Typography>
            <Typography paragraph>Kuuntele ja vastaa kysymykseen.</Typography>

            <iframe width="560" height="315" src="https://www.youtube.com/embed/Y1HIDtGZlXI?controls=0&autoplay=1" title="YouTube video player" frameborder="0" style={{ maxWidth: '95%' }} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            <Typography paragraph>Onko kappale hyvä?</Typography>
            
            <Button
                variant='outlined'
                color='success'
                onClick={next}
                sx={{ mr: 1 }}
            >
                Joo
            </Button>

            <Button
                variant='outlined'
                color='error'
                onClick={next}
            >
                Ei
            </Button>
        </>
    )
}


const Vaihe3 = ({ final }) => {
    const [value, setValue] = useState('')
    const [isRealistic, setRealistic] = useState(true)

    const changeValue = (e) => {
        const str = e.target.value
    
        if (/[0-9]/.test(str)) {
            setValue(str)

            if (Number(value) > 150) {
                return setRealistic(false)
            } else {
                setRealistic(true)
            }
        }
    }

    return (
        <>
            <Typography sx={{ mt: 2 }} variant='h3' component='h1'>Matemaattinen numerotehtävä</Typography>
            <Typography paragraph>
                Sinun täytyy antaa älykkyysosamääräsi tuloksen laskemiseksi.
            </Typography>
            <TextField
                inputProps={{ type: 'number' }}
                label='Älykkyysosamääräsi'
                onChange={(e) => changeValue(e)}
                error={!isRealistic}
                helperText={isRealistic ? 'Kirjoita älykkyysosamääräsi.' : 'Lopeta valehtelu!'}
                sx={{ mb: 2 }}
            />

            <br />
            
            <Button
                variant='contained'
                onClick={() => final(Number(value))}
            >
                Lopeta testi
            </Button>
        </>
    )
}

const Final = ({ iq }) => {
    return (
        <Box>
            <Typography sx={{ mt: 1 }} variant='h3' component='h1'>Älykkyystestin tulokset</Typography>
            <Typography paragraph>
                Kattavan älykkyystestimme tuloksena saimme tietoomme, että älykkyysosamääräsi on mahtavat <b>{iq} pojoa</b>.
            </Typography>

            <Typography paragraph>
                Kiitos älykkyystestimme suorittamisesta. Muistathan kertoa kaverillesi myös testistä.
            </Typography>
        </Box>
    )
}

export default Aloitus