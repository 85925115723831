import { Box, Grow, Typography, Button } from "@mui/material"
import { Link } from "react-router-dom"

const AIndex = () => {
    return (
        <>
            <Grow in timeout={2500}>

                <Box sx={{ mt: 2 }}>
                    <Typography variant='h1' component='h1' align='center'>Tekoäly.</Typography>
                </Box>

            </Grow>

            <Grow in timeout={4300}>
                <div>
                    <Typography variant='h5' component='p' align='center'>
                        Essee.tk:lla voit kirjoittaa nyt mitä tahansa. Vain taivas on rajana.
                    </Typography>
                </div>
            </Grow>

            <Grow in timeout={5000}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                    <Button variant='outlined' component={Link} to='/tekoaly/chat' size="large">
                        Tekoäly-chat
                    </Button>
                </Box>
            </Grow>

            <Grow in timeout={5500}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                    <Button size='' variant='outlined' color='secondary' component={Link} to='/tekoaly/kuva'>
                        Kuvageneraattori
                    </Button>
                </Box>
            </Grow>





        </>

    )
}

export default AIndex