import { Box, Typography } from "@mui/material"
import { useState } from "react"
import { useEffect } from "react"
import { parseDict } from "../Services/DictService"

const Dictionary = () => {
    const [text, setText] = useState(null)
    
    useEffect(() => {
        
    }, [])



    return (
        <Box sx={{ mt: 2 }}>
            <Typography variant='h2' component='h1'>Sanakirja</Typography>
            <Typography variant='body1' component='p'>
                Etkö jaksa opetella genetiivejä, partitiivejä, nominatiivejä jne? Ei hätää, sillä Essee.tk:sta löydät kaikenmoisia sanamuotoja kaikenlaisiin sanoihin.
                
                <br /><br />

                Valitettavasti tämä ominaisuus ei ole vielä käytössä. Kyllä muaki harmittais.

           </Typography>
        </Box>
    )
}

export default Dictionary