import { LoadingButton } from "@mui/lab"
import { Box, Button, Paper, TextField, Typography, Zoom } from "@mui/material"
import { useState } from "react"
import { RouterLink } from "../../Components/Markup"
import { getGeneration } from "../../Services/AiService"

const ImageGen = () => {
    const [subjectInp, setSubject] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [photoURL, setURL] = useState(null)
    
    const generate = () => {
        setLoading(true)
        setURL(null)
        getGeneration(subjectInp)
            .then(res => {
                setLoading(false)
                setURL(res.data.url)
                console.log(res)
            })
            .catch(err => {
                alert('Kuvaasi ei voitu generoida TURVALLISUUSSYISTÄ.')
                setLoading(false)
                console.log(err)
            })
    }

    return (
        <Box sx={{ mt: 2 }}>
            <Zoom in>
                <Typography variant='h3' component='h1'>Kuvageneraattori</Typography>
            </Zoom>

            <Zoom in timeout={500}>
                <Typography gutterBottom variant='h6' component='p'>Jos et jaksa tehdä kuvaamataidon työtä annetusta aiheesta, olet juuri oikeassa paikassa.</Typography>
            </Zoom>

            <Typography paragraph sx={{ mt: 2.5 }}>
                Kuvaamataito on erittäin haastava aine. Olen aina ihmetellyt, miten on mahdollista piirtää niin hienoja teoksia. Itse en ainakaan osaa - eikä osaa moni muukaan.
                Siksi on kehitetty laadukas kuvageneraattori, jolla generoit kuvan kuin kuvan aiheesta kuin aiheesta laadukkaan tekoälymme avulla.
            </Typography>

            <Paper sx={{ padding: 4 }} >
                <Typography variant='h4' component='h2'>
                    Piirrä tekoälyllä
                </Typography>

                <TextField
                    label='Mitä kuvan tulisi sisältää?'
                    value={subjectInp}
                    onChange={(e) => setSubject(e.target.value)}
                    sx={{ mt: 1 }}
                    fullWidth
                />

                <br />

                {
                    isLoading 
                        ? <LoadingButton loading sx={{ mt: 3, mb: 2 }}
                        />
                        : <Button
                            variant="contained"
                            onClick={generate}
                            sx={{ mt: 1.5, mb: 2 }}
                        >
                            Aloita piirtäminen
                        </Button>
                }



                <AIImage url={photoURL} />

            </Paper>

            <Typography variant='h4' component='h2' sx={{ mt: 1 }} gutterBottom>
                Miten se toimii?
            </Typography>

            <Typography paragraph>
            Tekoälyn kuvageneraatio on tekoälytekniikka, jota käytetään luomaan uusia kuvia käyttämällä algoritmeja. Tämä menetelmä on mahdollistanut luonnolliset ja luonnollisesti näyttävät kuvat, jotka voidaan luoda algoritmin avulla. Algoritmit pohjautuvat datan ja metadatatiedon käsittelyyn. Tiedon käsittelyn avulla voidaan luoda synteettisiä kuvia, joissa on todellisen näköisiä elementtejä. Nämä kuvat voivat olla käytettävissä tietokonegrafiikan ja visualisointi- tai opetussovellusten taustamateriaalina. Algoritmin käyttämällä tekoälyn kuvageneraatiolla voidaan luoda kuvia erilaisista esineistä, paikoista, ihmisistä, eläimistä ja muista luonnollisista elementeistä. Algoritmi luo kuvan datan ja metadatatiedon analysoimisen ja syntetisoimisen avulla. Näin algoritmi voi luoda kuvan epätarkasti määritellyistä lähteistä, ja tämä tekee tekoälyn kuvageneraatiosta erinomaisen tietokonegrafiikan työkalun.
            </Typography>

            <Typography paragraph>
                Äsköisen tekstin oli muuten kirjoittanut <RouterLink to='/tekoaly/chat'>Tekoäly-chat</RouterLink>. Käyppä testaileen! Ite en kyl ikinä jaksais kirjottaa tollasta.
            </Typography>


        </Box>
    )
}

const AIImage = ({ url }) => {
    if (!url) {
        return null
    }

    return (
        <>
            <Typography variant='h5' component='h3' gutterBottom>Piirtämäsi kuva</Typography>
            <a href={url} rel="noreferrer" target='_blank'><img src={url} alt='Tekoälyn piirtämä kuva.' width='300' height='300' style={{ maxWidth: '95%' }} /></a>

            <br />

            <Button
                variant="outlined"
                color="secondary"
                href={url}
                target='_blank'
                rel="noreferrer"
            >
                Näytä kuva laajempana
            </Button>
        </>
    )
}

export default ImageGen