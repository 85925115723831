import { Box, Button, Card, CardContent, TextField, Typography, useScrollTrigger, Slide, Toolbar, AppBar } from "@mui/material"
import { useState } from "react"
import { getCompletion } from "../../Services/AiService"

import { LoadingButton } from "@mui/lab"

class Message {
    constructor(isAI, content) {
        this.isAI = isAI
        this.content = content
    }
}

const AI = () => {
    const [messageInput, setInput] = useState('')
    const [messages, setMessages] = useState([])

    const [isLoading, setLoading] = useState(false)

    console.log(messages)

    const send = () => {
        if (isLoading) {
            return
        }

        const yourMessage = new Message(false, messageInput)
        setMessages([...messages, yourMessage])

        setLoading(true)
        setInput('')

        getCompletion(messageInput)
            .then(res => {
                console.log(res)
                setMessages([...messages, yourMessage, new Message(true, res.data.response)])
                setLoading(false)
            })
            .catch(err => {
                if (err && err.response && err.response.status && err.response.status === 422) {
                    setLoading(false)
                    return setMessages([...messages, yourMessage, new Message(true, 'Valitanpa nyt hieman: en halua vastata sinulle viestiisi. Koitappa kysyä minulta asiallisia asioita!')])
                } 
                
                console.error(err)
                setLoading(false)
                return setMessages([...messages, yourMessage, new Message(true, 'Heh hehe heh, eipä taida kuulua! Virhe!')])
            })
    }


    return (
        <>

        <Box sx={{ mt: 2 }}>
            <Typography variant="h3" component='h1'>Tekoäly</Typography>
            <Typography variant='subtitle1' component='p' gutterBottom>Nyt voit keskustella teköälyn kanssa!</Typography>

            {
                messages.map((msg, index) => {
                    console.log(msg)
                    return msg.isAI ? <AIMessage key={index} content={msg.content} /> : <UserMessage content={msg.content} key={index} />
                })
            }



            

        </Box>

            <AppBar position="sticky" >
                <Toolbar component='form' onSubmit={(e) => { e.preventDefault(); send() }} sx={{ width: '100%' }}>
                    <TextField
                        label='Kirjoita viesti'
                        value={messageInput}
                        onChange={(e) => setInput(e.target.value)}
                        sx={{ width: '95%', mr: 0.5, mt: 1, mb: 1 }}
                        multiline
                    />

                    {
                        isLoading ? 
                            <LoadingButton loading />
                            : <Button
                            variant='contained'
                            color='primary'
                            size='large'
                            sx={{ }}
                            disabled={messageInput === ''}
    
                            type='submit'
                        >
                            Lähetä
                        </Button>
                    }

                </Toolbar>
            </AppBar>

        </>
    )
}

const AIMessage = ({ content }) => {
    return (
        <Box sx={{ display: 'flex', mt: 1.5, mb: 1.5, alignItems: 'left', justifyContent: 'left' }}>
            <Card sx={{ width: '35rem' }}>
                <CardContent sx={{ maxWidth: '34rem' }}>
                    <Typography variant='h6' color='text.secondary'>Teköälyn viesti</Typography>
                    <Typography>{content}</Typography>
                </CardContent>
            </Card>
        </Box>
    )
}

const UserMessage = ({ content }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'right', justifyContent: 'right', mt: 1.5, mb: 1.5 }}>
            <Card sx={{ width: '35rem' }}>
                <CardContent sx={{ maxWidth: '34rem' }}>
                    <Typography variant='h6' color='text.secondary'>Sinun viestisi</Typography>
                    <Typography>{content}</Typography>
                </CardContent>
            </Card>
        </Box>
    )
}

export default AI